<template>
  <div id="sonic">
    <b-row class="m-0">
      <b-col xl="1"></b-col>
      <b-col xl="10">
        <b-card header-tag="nav" class="mt-4 mb-4">
          <template v-slot:header>
            <b-navbar toggleable="lg" class="text-center">
              <b-navbar-brand href="#">
                <router-link class="text-dark" :to="{ name: 'sonic.view' }">{{$t('sonic.title')}}</router-link>
              </b-navbar-brand>

              <b-navbar-toggle target="sonic-nav"></b-navbar-toggle>

              <b-collapse id="sonic-nav" is-nav>
                <b-navbar-nav>
                  <b-nav-item>
                    <router-link :to="{ name: 'sonic.view' }">{{$t('sonic.nav.overview')}}</router-link>
                  </b-nav-item>
                  <b-nav-item v-if="projectId !== ''">
                    <router-link
                      :to="{ name: 'sonic.input', params: { id: projectId } }"
                    >{{$t('sonic.nav.input')}}</router-link>
                  </b-nav-item>
                  <b-nav-item v-if="projectId !== '' && Object.keys(results).length > 0">
                    <router-link
                      :to="{ name: 'sonic.output', params: { id: projectId } }"
                    >{{$t('sonic.nav.output')}}</router-link>
                  </b-nav-item>
                </b-navbar-nav>

                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto justify-content-center align-items-center">
                  <b-button 
                  variant="success"
                  v-if="isProjectCreatable()"
                  @click="openCreateProjectForm"
                  ><i class="fas fa-plus"></i> {{$t('sonic.nav.create')}}</b-button>
                  <b-link class="ml-3 p-0 text-muted" :disabled="true">
                    <div class="h3 m-0 p-0">
                      <i class="fas fa-cog"></i>
                    </div>
                  </b-link>
                </b-navbar-nav>
              </b-collapse>
            </b-navbar>
          </template>
          <router-view :key="$route.path" />
        </b-card>
      </b-col>
      <b-col xl="1"></b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import water from './water/index'
export default {
  name: "sonic.index",
  computed: {
    ...mapGetters({
      projectId: "getSonicCurrentProjectId",
      settings: "getSonicSettings",
      results: "getSonicResults"
    }),
    
  },
  methods: {
    openCreateProjectForm() {
      this.$root.$emit("bv::show::modal", "modal-create-project");
    },
    isProjectCreatable() {
      return this.$router.currentRoute.name === "sonic.view"
    }
  },
};
</script>

<style>
</style>